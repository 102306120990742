/*
 * Copyright 2022 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */

// Detect whether the app is being run with Lighthouse by looking at the user-agent string.
// The default Lighthouse user-agent string contains `Chrome-Lighthouse`:
// https://github.com/GoogleChrome/lighthouse/blob/62783de7f32a94a5f70e5430ff65b2b957a73d0f/core/config/constants.js#L84-L85
export const isLighthouse = globalThis?.navigator.userAgent.includes('Chrome-Lighthouse') === true;
