/*
 * Copyright 2022 DevIntent - All Rights Reserved
 *
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * without the express permission of DevIntent.
 *
 * No warranty, explicit or implicit, provided.
 * In no event shall DevIntent be liable for any claim or damages related to this code.
 */
import packageInfo from '../../package.json';
import { isLighthouse } from './is-lighthouse';

export const environment = {
  production: true,
  lighthouse: isLighthouse,
  serviceWorkerEnabled: true,
  firebase: {
    apiKey: 'AIzaSyBsGPtL6QWaLQVhWebMZDhexh7dDvmigrU',
    authDomain: 'devintentweb.firebaseapp.com',
    databaseURL: 'https://devintentweb.firebaseio.com',
    projectId: 'devintentweb',
    storageBucket: 'devintentweb.appspot.com',
    messagingSenderId: '277388967584',
    appId: '1:277388967584:web:592555edc036aba5',
    // Use the staging ID if running with Lighthouse, otherwise use the production ID.
    measurementId: isLighthouse ? 'G-YM3S2JXZ97' : 'G-JG9TB3BL3F',
  },
  version: packageInfo.version,
  apiUrl: '/api/v1/',
};
